import { connect } from 'react-redux';
import PostOptionsModal from './PostOptionsModal';
import { openModal, closeModal } from '../../actions/ui_actions';
import { modalTypes as MODAL_TYPES } from '../../constants/modalTypes';

// functionalities:
// 1 - copy post URL
// 2 - send invite(s)
// 3 - follow post author
// 4 - report event

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
  postId: state.ui.modals.postOptionsModal,
  inviteModalOpen: !!state.ui.modals.inviteModal,
});

const mapDispatchToProps = dispatch => ({
  openInviteModal: () => dispatch(openModal(MODAL_TYPES.INVITE_MODAL, true)),
  closeInviteModal: () => dispatch(closeModal(MODAL_TYPES.INVITE_MODAL)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PostOptionsModal);

import axios from 'axios';
import { put, takeEvery, takeLatest, call, select } from 'redux-saga/effects';

import * as types from '@happenings/components/constants/actionTypes';
import { getCurrUser, getToken } from '@happenings/components/session';
import { PageState } from '@happenings/components/pagination';
import { getFetchParams } from '@happenings/components/util';
import { getPostData } from '@happenings/components/post';
import { ApiAction, ApiPayload } from '../common/types';
import { ActionTypes, Action, getNotifications } from './index';
import Store from '../store';


interface inboxAction extends ApiAction {
  payload: ApiPayload & { id?: number };
}

const PAGE_SIZE = 20;

export const getPagination = (state: Store): PageState => state.pagination.inbox;

export function* getUserNotifications({ payload }: inboxAction) {
  try {
    const { lastEvaluatedKey, hasMore } = yield select(getPagination);
    if (hasMore) {
      const token = yield select(getToken);
      const user = yield select(getCurrUser);
      const pageParam = lastEvaluatedKey ? `?startAfter=${lastEvaluatedKey}` : '';
      const url = `${payload.url}/api/inbox/${user.id}${pageParam}`;
      const params = getFetchParams(token);
      yield put({ type: types.BEGIN_NOTIFICATION_FETCH, url });
      const res = yield call(axios.get, url, params);
      const action: Action = {
        type: ActionTypes.RECEIVE_NOTIFICATION_PAGE,
        payload: { notifications: res.data },
      };
      yield put(action);
      for (const notification of res.data) {
        if (notification.post_id) {
          yield put(getPostData(notification.post_id));
        }
      }
    }
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: 500 });
  }
}

export function* markNotificationAsRead({ payload }: inboxAction) {
  try {
    const token = yield select(getToken);
    const user = yield select(getCurrUser);
    const url = `${payload.url}/api/inbox/${user.id}`;
    yield call(axios.put, url, { notificationId: payload.id }, getFetchParams(token));
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: e.response.status });
  }
}

export function* deleteNotification({ payload }: inboxAction) {
  try {
    const user = yield select(getCurrUser);
    const url = `${payload.url}/api/inbox/${user.id}?notificationId=${payload.id}`;
    const token = yield select(getToken);
    const params = getFetchParams(token);
    yield call(axios.delete, url, params);

    // re-fetch updated server-side data
    yield put(getNotifications());
  } catch (e) {
    yield put({ type: types.RECEIVE_SESSION_ERRORS, statusCode: 500 });
  }
}

export default function* main() {
  yield takeLatest(ActionTypes.GET_NOTIFICATIONS, getUserNotifications);
  yield takeEvery(ActionTypes.DELETE_NOTIFICATION, deleteNotification);
  yield takeEvery(ActionTypes.READ_NOTIFICATION, markNotificationAsRead);
}

import * as types from '@happenings/components/constants/actionTypes';
import {
  ActionTypes as NotificationActions,
  Notification,
} from '@happenings/components/inbox';
import { FeedActionTypes } from '@happenings/components/feed';
import { ActionTypes as InboxActions } from '@happenings/components/inbox';
import { actionTypes as ProfileActions } from '@happenings/components/profile';
import { actionTypes as profileActions } from '@happenings/components/profile';

type PageStrategy = (candidates: number[]) => number | null;

// TODO: move all of this logic out of the client into the API.
// have everything use the 'LAST_FIRST' strategy and then remove
// the PagingStrategies concept entirely.
export const PagingStrategies: Record<string, PageStrategy> = {
  // when displaying the lowest (oldest) incrementing ID first,
  // the highest ID among the candidates is the last evaluated key.
  OLDEST_FIRST: (candidates) => Math.max(...candidates),
  // when displaying the highest (newest) incrementing ID first,
  // the loweset ID among the candidates is the last evaluated key.
  NEWEST_FIRST: (candidates) => Math.min(...candidates),
  // when the ordering is defined by the API, the last candidate in
  // the page of results is the last evaluated key.
  LAST_FIRST: (candidates) => candidates.pop() || null,
};

export const selectLastEvaluatedKey = (
  prevKey: number | null,
  candidates: number[],
  strategy = PagingStrategies.NEWEST_FIRST
): number | null => (candidates.length === 0 ? prevKey : strategy(candidates));

export interface PageState {
  lastEvaluatedKey: number | null;
  hasMore: boolean;
  keys: number[];
}

export const DEFAULT_STATE: PageState = {
  lastEvaluatedKey: null,
  hasMore: true,
  keys: [],
};

export interface ProfileState {
  ATTENDING: PageState;
  HOST: PageState;
  STARRED: PageState;
}

export type Pagination = {
  inbox: PageState;
  feed: PageState;
  // keyed by username
  profile: Record<string, ProfileState>;
};

const PROFILE_DEFAULT_STATE = {
  ATTENDING: DEFAULT_STATE,
  HOST: DEFAULT_STATE,
  STARRED: DEFAULT_STATE,
};

const INITIAL_STATE: Pagination = {
  inbox: DEFAULT_STATE,
  feed: DEFAULT_STATE,
  profile: {},
};

/**
 * @param prevKey the last evaluated key
 * @param prevKeys keys already in the store
 * @param pageKeys new list of keys from the server
 */
export const paginator = (
  prevKey: number | null,
  prevKeys: number[],
  pageKeys: number[],
  strategy = PagingStrategies.NEWEST_FIRST
): PageState => ({
  lastEvaluatedKey: selectLastEvaluatedKey(prevKey, pageKeys, strategy),
  hasMore: pageKeys.length > 0,
  // append to the end of the list
  keys: [...prevKeys, ...pageKeys],
});

const withDefaultFallback = (
  ps: Record<string, ProfileState>,
  username: string
): ProfileState => {
  if (!ps[username]) {
    return PROFILE_DEFAULT_STATE;
  }
  return ps[username];
};

const reduceFeedTypeForUsername = (
  ps: Record<string, ProfileState>,
  username: string,
  feedType: keyof ProfileState,
  postIDs: number[]
) => {
  const newState = withDefaultFallback(ps, username);
  const newTimeline = newState[feedType] ?? DEFAULT_STATE;

  return paginator(
    newTimeline.lastEvaluatedKey,
    newTimeline.keys,
    postIDs,
    PagingStrategies.LAST_FIRST
  );
};

export type Action =
  | {
      type: NotificationActions.RECEIVE_NOTIFICATION_PAGE;
      payload: { notifications: Notification[] };
    }
  | { type: NotificationActions.RESET_NOTIFICATIONS }
  | { type: NotificationActions.DELETE_NOTIFICATION; payload: { id: number } }
  | {
      type: FeedActionTypes.RECEIVE_FEED_PAGE;
      feedName: string;
      posts: { seqNum: number }[];
    }
  | { type: FeedActionTypes.RESET_FEED; payload: { feedName: string } }
  | {
      type: ProfileActions.RECEIVE_PROFILE_POSTS;
      posts: { id: number }[];
      feedType: keyof ProfileState;
      username: string;
    }
  | { type: profileActions.RESET_PROFILE_STATE; payload: { username: string } }
  | { type: FeedActionTypes.TIMELINE_FEED_CHANGE }
  | { type: typeof types.ROUTE_CHANGE };

const paginationReducer = (
  state = INITIAL_STATE,
  action: Action
): Pagination => {
  switch (action.type) {
    case FeedActionTypes.RECEIVE_FEED_PAGE:
      return {
        ...state,
        feed: {
          ...state.feed,
          ...paginator(
            state.feed.lastEvaluatedKey,
            state.feed.keys,
            action.posts.map((p) => p.seqNum)
          ),
        },
      };
    case FeedActionTypes.RESET_FEED:
      return {
        ...state,
        feed: DEFAULT_STATE,
      };
    case NotificationActions.RECEIVE_NOTIFICATION_PAGE:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          ...paginator(
            state.inbox.lastEvaluatedKey,
            state.inbox.keys,
            action.payload.notifications.map((n) => n.id)
          ),
        },
      };
    case InboxActions.DELETE_NOTIFICATION:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          keys: state.inbox.keys.filter((k) => k !== action.payload.id),
        },
      };
    case NotificationActions.RESET_NOTIFICATIONS:
      return {
        ...state,
        inbox: DEFAULT_STATE,
      };
    case ProfileActions.RECEIVE_PROFILE_POSTS:
      return {
        ...state,
        profile: {
          ...state.profile,
          [action.username]: {
            ...withDefaultFallback(state.profile, action.username),
            [action.feedType]: {
              ...reduceFeedTypeForUsername(
                state.profile,
                action.username,
                action.feedType,
                action.posts.map((p) => p.id)
              ),
            },
          },
        },
      };
    case profileActions.RESET_PROFILE_STATE:
      return {
        ...state,
        profile: {
          ...state.profile,
          [action.payload.username]: PROFILE_DEFAULT_STATE,
        },
      };
    // to-do: don't clear once we can initialize profilePostIds in profile
    // with the paginated postIds for the new timeline
    case FeedActionTypes.TIMELINE_FEED_CHANGE:
      return {
        ...state,
        profile: {},
      };
    case types.ROUTE_CHANGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default paginationReducer;

import { FetchAction } from '@happenings/components/common/types';

import Store from '../store';
import { mapEntitiesById } from '../util';

export enum NotificationType {
  FOLLOWED_BY_USER = 'FOLLOWED_BY_USER',
  USER_ATTENDING = 'USER_ATTENDING',
  USER_COMMENTED = 'USER_COMMENTED',
  USER_STARRED = 'USER_STARRED',
  INVITE_TO_EVENT = 'INVITE_TO_EVENT',
  SHARE_EVENT = 'SHARE_EVENT',
  EVENT_TIME_CHANGE = 'EVENT_TIME_CHANGE',
  LOCATION_CHANGE = 'LOCATION_CHANGE',
  EVENT_CANCELLED = 'EVENT_CANCELLED',
  EVENT_UPDATED = 'EVENT_UPDATED',
  FOLLOW_REQUEST = 'FOLLOW_REQUEST',
}

export interface Notification {
  id: number;
  post_id?: number;
  notification_type: NotificationType;
  notifier_username: string;
  created: string;
  user_id: number;
  read: boolean;
}

// Notification types related to specific posts (not specific users)
export const POST_NOTIFICATION_TYPES: Set<NotificationType> = new Set([
  NotificationType.USER_ATTENDING,
  NotificationType.USER_COMMENTED,
  NotificationType.USER_STARRED,
  NotificationType.INVITE_TO_EVENT,
  NotificationType.SHARE_EVENT,
  NotificationType.EVENT_TIME_CHANGE,
  NotificationType.EVENT_CANCELLED,
  NotificationType.EVENT_UPDATED,
]);

export const conjugateNotification = (
  notificationType: string
): string | undefined => {
  const conjugationMap: Record<string, string> = {
    [NotificationType.FOLLOWED_BY_USER]: 'followed you',
    [NotificationType.USER_ATTENDING]: 'is attending your event',
    [NotificationType.USER_COMMENTED]: 'commented on your event',
    [NotificationType.USER_STARRED]: 'saved your event',
    [NotificationType.INVITE_TO_EVENT]: 'invited you to an event',
    [NotificationType.SHARE_EVENT]: 'shared an event with you',
    [NotificationType.EVENT_TIME_CHANGE]: 'updated the time of their event',
    [NotificationType.LOCATION_CHANGE]: 'changed the location of their event',
    [NotificationType.EVENT_CANCELLED]: 'cancelled their event',
    [NotificationType.EVENT_UPDATED]: 'updated their event',
    [NotificationType.FOLLOW_REQUEST]: 'wants to follow you',
  };
  return conjugationMap[notificationType];
};

export const countUnreadNotifications = (state: Store): number => {
  if (
    state.session.currentUser &&
    state.ui.loading.notificationsLoading === false
  ) {
    return Object.values(state.entities.notifications).filter(
      (n: Notification) => n.read === false
    ).length;
  }
  return 0;
};

export enum ActionTypes {
  RECEIVE_NOTIFICATION_PAGE = 'RECEIVE_NOTIFICATION_PAGE',
  RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS',
  GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
  READ_NOTIFICATION = 'READ_NOTIFICATION',
  DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',
}

export const resetNotifications = (): Action => ({
  type: ActionTypes.RESET_NOTIFICATIONS,
});

export const getNotifications = (): FetchAction => ({
  type: ActionTypes.GET_NOTIFICATIONS,
  payload: {},
});

export const readNotification = (
  id: number
): Action => ({
  type: ActionTypes.READ_NOTIFICATION,
  payload: { id },
});

export const deleteNotification = (
  id: number
): Action => ({
  type: ActionTypes.DELETE_NOTIFICATION,
  payload: { id },
});

export type Inbox = Record<number, Notification>;

const DEFAULT_STATE: Inbox = {};

export type Action =
  | { type: ActionTypes.RECEIVE_NOTIFICATION_PAGE; payload: { notifications: Notification[] } }
  | { type: ActionTypes.READ_NOTIFICATION; payload: { id: number } }
  | { type: ActionTypes.DELETE_NOTIFICATION; payload: { id: number } }
  | { type: ActionTypes.RESET_NOTIFICATIONS };

const inboxReducer = (state: Inbox = DEFAULT_STATE, action: Action): Inbox => {
  switch (action.type) {
    case ActionTypes.RECEIVE_NOTIFICATION_PAGE:
      return {
        ...state,
        ...(mapEntitiesById(action.payload.notifications) as Record<
          number,
          Notification
        >),
      };
    // optimistic update
    case ActionTypes.READ_NOTIFICATION:
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], read: true },
      };
    case ActionTypes.DELETE_NOTIFICATION:
      // eslint-disable-next-line no-case-declarations
      const { [action.payload.id]: _, ...rest } = state;
      return rest;
    case ActionTypes.RESET_NOTIFICATIONS:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default inboxReducer;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { AGE_RESTRICTION_DISPLAY } from '../../constants/ageRestrictions';
import CopyEventLink from './CopyEventLink';
import ProgressiveImage from '../util/ProgressiveImageLoader';

const ATTENDANCE_PREVIEW = 3;

const PostImageDetailToggler = (props) => {
  const { detailViewOpen, toggleDetailView } = props;

  const getAttendanceString = (attendance) => {
    const userList = attendance.map(a => a.username);
    if (attendance.length <= ATTENDANCE_PREVIEW) {
      return userList.join(', ');
    }
    const othersNum = userList.length - ATTENDANCE_PREVIEW;
    const otherWord = othersNum === 1 ? 'other' : 'others';
    return `${userList[0]}, ${userList[1]} ${userList[2]} and ${othersNum} ${otherWord}`;
  };

  // props & derived vars for post actions
  const {
    post,
    postIsStarred,
    userIsAttending,
    userIsLoggedIn,
    userIsAuthor,
    isInviting,
    setIsInviting,
    postImageRef,
    imageHeightPx,
    updateStar,
    updateAttending,
  } = props;

  const {
    title,
    description,
    location,
    eventTimestamp,
    isAccessible,
    ageRestriction,
    publicUrl,
  } = post;

  const userCanInvite = userIsAuthor || post.guestsCanInvite;
  const dateString = moment(eventTimestamp).format('LL');

  const startBtnText = postIsStarred ? 'Saved!' : 'Save';
  const attendBtnTxt = userIsAttending ? 'Going!' : 'Attend';

  // const attendButtonClass = classNames('post-btn item', { 'active': userIsAttending });
  // const starButtonClass = classNames('post-btn item', { 'active': postIsStarred });
  const attendBtnClass = classNames('post-btn item', { 'active': userIsAttending });
  const starBtnClass = classNames('post-btn item', { 'active': postIsStarred });

  const DetailView = () => (
    <div className="detail-view" style={{ minHeight: `${imageHeightPx}px` }}>
      <div
        role="button"
        className="toggle-area"
        onClick={toggleDetailView}
      >
        <div className="event-title">{title}</div>
        <div className="detail-row">
          <span className="detail-header">Location: </span>
          <span>{location}</span>
        </div>
        <div className="detail-row">
          <span className="detail-header">Date: </span>
          <span>{dateString}</span>
        </div>
        <div className="detail-row">
          <p className="detail-header">
            {AGE_RESTRICTION_DISPLAY[ageRestriction]}
          </p>
        </div>
        {isAccessible && <div className="detail-row">
          <p className="detail-header">
            Wheelchair Accessible
          </p>
        </div>}
        {description && <div className="detail-row">
          <span className="detail-header">Description: </span>
          <span>{description}</span>
        </div>}
        {
          props.attendees.length ? (
            <div className="detail-row">
              <Link to={`/event/${post.id}/attendance`}>
                <span className="detail-header">Attending: </span>
              </Link>
              <span>{getAttendanceString(props.attendees)}</span>
            </div>
          ) : null
        }
      </div>

      {/* Post actions section */}
      <div>
        <div className="post-stats-container">
          <div className="stat-container">
            {/* Copy post */}
            <CopyEventLink postId={post.id} />
            <button
              className={starBtnClass}
              type="button"
              // toggles the current val of isStarred
              onClick={() => updateStar(postIsStarred)}
            >
              {startBtnText}
            </button>

            <button
              className={attendBtnClass}
              type="button"
              // toggles the current val of isAttending
              onClick={() => updateAttending(userIsAttending)}
            >
              {attendBtnTxt}
            </button>
            {/* Invite users to post */}
            <div className="stat-container">
            {/* {userCanInvite
              && (
                <button
                  className="post-btn item"
                  type="button"
                  style={{ minWidth: 52 }}
                  onClick={() => {
                    setIsInviting(true);
                  }}
                >
                  Invite
                </button>
              )} */}

            {/* Edit post */}
            {userIsAuthor
              && (
                <button
                  className="post-btn item"
                  type="button"
                  style={{ minWidth: 60 }}

                >
                  <Link to={`/event/${post.id}/edit`}>
                    Edit
                  </Link>
                </button>
              )}
            {/* Delete post */}
            {userIsAuthor
              && (
                <button className="post-btn item" type="button" style={{ minWidth: 60 }}>
                  Delete
                </button>
              )
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const previewUrl = publicUrl.includes('/full/')
    ? publicUrl.replace('/full/', '/preview/')
    : '';

  return (
    <div className="post-detail-container">
      {detailViewOpen && !isInviting && <DetailView />}
      {/* {isInviting && <InviteView />} */}
      {!detailViewOpen && !isInviting
        && (
          <div
            role="button"
            className="toggle-area"
            onClick={() => toggleDetailView()}
          >
            <ProgressiveImage
              src={publicUrl}
              placeholderSrc={previewUrl}
              ref={postImageRef}
              alt={title}
            />
          </div>
        )}
    </div>
  );
};

PostImageDetailToggler.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    location: PropTypes.string,
    description: PropTypes.string,
    isPrivate: PropTypes.bool,
    guestsCanInvite: PropTypes.bool,
    isAccessible: PropTypes.bool,
    ageRestriction: PropTypes.string,
    eventTimestamp: PropTypes.string,
    publicUrl: PropTypes.string,
    thumbPublicUrl: PropTypes.string,
  }).isRequired,
  postIsStarred: PropTypes.bool.isRequired,
  userIsAttending: PropTypes.bool.isRequired,
  updateAttending: PropTypes.func.isRequired,
  userIsLoggedIn: PropTypes.bool.isRequired,
  userIsAuthor: PropTypes.bool.isRequired,
  detailViewOpen: PropTypes.bool.isRequired,
  toggleDetailView: PropTypes.func.isRequired,
  isInviting: PropTypes.bool.isRequired,
  setIsInviting: PropTypes.func.isRequired,
  imageHeightPx: PropTypes.number.isRequired,
  postImageRef: PropTypes.shape({ current: PropTypes.element }).isRequired,
  updateStar: PropTypes.func.isRequired,
  attendees: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string,
    }),
  ).isRequired,
};

export default PostImageDetailToggler;

import { connect } from 'react-redux';

import { getPostData } from '@happenings/components/post';
import { readNotification, deleteNotification } from '@happenings/components/inbox';

import Notification from './Notification';

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.session.currentUser,
  event: ownProps.postId ? state.entities.posts[ownProps.postId] : null,

  // only if notification has an associated event, wait for that to load in
  eventLoaded: ownProps.postId ? !!state.entities.posts[ownProps.postId] : false,
});

const mapDispatchToProps = dispatch => ({
  getPostData: postId => dispatch(getPostData(postId)),
  markAsRead: (userId, notificationId) => dispatch(readNotification(userId, notificationId)),
  deleteNotification: (notificationId) => dispatch(deleteNotification(notificationId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notification);
